import { Text, ScrambledText } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'
import { NextPage } from 'next'
import React from 'react'
import s from './GeoPage.module.scss'
import PageBackground from 'compositions/PageBackground/PageBackground'
import cx from 'classnames'
import { useDevice } from 'device'
import { Button } from 'components/inputs'


const GeoPage: NextPage = () => {
  const { isMobile } = useDevice()

  return (
    <PageBackground>
      <WidthContainer>
        <div className={cx('flex flex-col justify-center text-center', s.top)}>
          <img src={isMobile ? '/images/map_mobile.webp' : '/images/map_desktop.webp'} alt={'Not available'} className={isMobile ? 'mb-24' : 'mb-32 px-12'} />
          <ScrambledText
            className={isMobile ? 'mb-24' : 'mb-16'}
            style="h5"
            message="Babylon7 isn’t available in your country"
            color="gray-100"
          />
          <ScrambledText
            className="mb-32"
            style="sub"
            message="Some jurisdictions are just slower to accept change. We recommend you access the platform from a more degen-friendly country."
            color="gray-60"
          />
          <Button
            className={s.button}
            title="+ Visit babylon +"
            style="white"
            href="https://babylon.art"
            target="_blank"
          />
        </div>
        {
          !isMobile && (
            <div className="flex justify-between items-end mb-48">
              <Text style="glitch18" message={'[ June 20 ]'} />
              <div>
                <Text style="glitch18" message="7.babylon.art" />
                <Text style="glitch18" message="st$tus: re%u\ld/ng.." />
              </div>
            </div>
          )
        }
      </WidthContainer>
    </PageBackground>
  )
}

export default GeoPage
