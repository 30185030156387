import React, { FC } from 'react'
import s from './PageBackground.module.scss'


const PageBackground: FC = ({ children }) => {
  return (
    <>
      <div className={s.bg} />
      <div className="relative">
        {children}
      </div>
    </>
  )
}

export default PageBackground
